<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main class="space-y-10">
          <section class="bg-white rounded-xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">簡訊王</h1>

              <vs-switch color="success" v-model="kotsms_loginActive" :disabled="!(kotsms_account && kotsms_pawd)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">帳號</h2>
                <div class="relative">
                  <ValidationProvider name="簡訊王帳號" :rules="`${kotsms_loginActive ? 'required|max:150' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="kotsms_account" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full">
                <h2 class="title">密碼</h2>
                <div class="relative">
                  <ValidationProvider name="簡訊王密碼" :rules="`${kotsms_loginActive ? 'required|max:30' : ''}`" v-slot="{ errors }">
                    <vs-input type="password" v-model="kotsms_pawd" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>

          <section class="bg-white rounded-xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">EVERY8D</h1>

              <vs-switch color="success" v-model="e8d_loginActive" :disabled="!(e8d_account && e8d_pawd)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">帳號</h2>
                <div class="relative">
                  <ValidationProvider name="EVERY8D帳號" :rules="`${e8d_loginActive ? 'required|max:150' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="e8d_account" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full">
                <h2 class="title">密碼</h2>
                <div class="relative">
                  <ValidationProvider name="EVERY8D密碼" :rules="`${e8d_loginActive ? 'required|max:30' : ''}`" v-slot="{ errors }">
                    <vs-input type="password" v-model="e8d_pawd" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kotsms_loginActive: false,
      kotsms_account: '',
      kotsms_pawd: '',
      e8d_loginActive: false,
      e8d_account: '',
      e8d_pawd: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getNewsLetterData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.kotsms_login = data.kotsms_login
          this.kotsms_account = data.kotsms_account
          this.kotsms_pawd = data.kotsms_pawd
          this.e8d_login = data.e8d_login
          this.e8d_account = data.e8d_account
          this.e8d_pawd = data.e8d_pawd

          data.kotsms_login === 1 ? (this.kotsms_loginActive = true) : (this.kotsms_loginActive = false)
          data.e8d_login === 1 ? (this.e8d_loginActive = true) : (this.e8d_loginActive = false)
        } else {
          console.error(res.data.Message)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        kotsms_login: this.kotsms_login,
        kotsms_account: this.kotsms_account,
        kotsms_pawd: this.kotsms_pawd,
        e8d_login: this.e8d_login,
        e8d_account: this.e8d_account,
        e8d_pawd: this.e8d_pawd,
      }

      this.$axios({
        url: 'front/set/app/uNewsLetterData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
  computed: {
    kotsms_login: {
      get() {
        return this.kotsms_loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    e8d_login: {
      get() {
        return this.e8d_loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    kotsms_account: function () {
      if (this.kotsms_account === '' && this.kotsms_pawd === '') {
        this.kotsms_loginActive = false
      }
    },
    kotsms_pawd: function () {
      if (this.kotsms_account === '' && this.kotsms_pawd === '') {
        this.kotsms_loginActive = false
      }
    },
    e8d_account: function () {
      if (this.e8d_account === '' && this.e8d_pawd === '') {
        this.e8d_loginActive = false
      }
    },
    e8d_pawd: function () {
      if (this.e8d_account === '' && this.e8d_pawd === '') {
        this.e8d_loginActive = false
      }
    },
    kotsms_loginActive: function (newval, oldval) {
      if (newval === true) {
        this.e8d_loginActive = false
      }
    },
    e8d_loginActive: function (newval, oldval) {
      if (newval === true) {
        this.kotsms_loginActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
  line-height: 20px;
}
</style>
