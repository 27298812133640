var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{staticClass:"flex-shrink-0",attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))])],1),_c('main',{staticClass:"space-y-10"},[_c('section',{staticClass:"bg-white rounded-xl p-4 space-y-4"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"bigTitle"},[_vm._v("簡訊王")]),_c('vs-switch',{attrs:{"color":"success","disabled":!(_vm.kotsms_account && _vm.kotsms_pawd)},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("已停用")])]},proxy:true},{key:"on",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("啟用中")])]},proxy:true}],null,true),model:{value:(_vm.kotsms_loginActive),callback:function ($$v) {_vm.kotsms_loginActive=$$v},expression:"kotsms_loginActive"}})],1),_c('div',{staticClass:"flex w-full space-x-4"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("帳號")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"簡訊王帳號","rules":("" + (_vm.kotsms_loginActive ? 'required|max:150' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.kotsms_account),callback:function ($$v) {_vm.kotsms_account=$$v},expression:"kotsms_account"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("密碼")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"簡訊王密碼","rules":("" + (_vm.kotsms_loginActive ? 'required|max:30' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password"},model:{value:(_vm.kotsms_pawd),callback:function ($$v) {_vm.kotsms_pawd=$$v},expression:"kotsms_pawd"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('section',{staticClass:"bg-white rounded-xl p-4 space-y-4"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"bigTitle"},[_vm._v("EVERY8D")]),_c('vs-switch',{attrs:{"color":"success","disabled":!(_vm.e8d_account && _vm.e8d_pawd)},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("已停用")])]},proxy:true},{key:"on",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("啟用中")])]},proxy:true}],null,true),model:{value:(_vm.e8d_loginActive),callback:function ($$v) {_vm.e8d_loginActive=$$v},expression:"e8d_loginActive"}})],1),_c('div',{staticClass:"flex w-full space-x-4"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("帳號")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"EVERY8D帳號","rules":("" + (_vm.e8d_loginActive ? 'required|max:150' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.e8d_account),callback:function ($$v) {_vm.e8d_account=$$v},expression:"e8d_account"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("密碼")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"EVERY8D密碼","rules":("" + (_vm.e8d_loginActive ? 'required|max:30' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password"},model:{value:(_vm.e8d_pawd),callback:function ($$v) {_vm.e8d_pawd=$$v},expression:"e8d_pawd"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('hr'),_c('div',{staticClass:"flex justify-end"},[_c('vs-button',{ref:"saveData",attrs:{"size":"large","disabled":invalid},on:{"click":function($event){return _vm.saveData(invalid)}}},[_c('span',{staticClass:"font-medium"},[_vm._v("儲存")])])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }